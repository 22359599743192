<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container hardware-product" :style="'background: url('+bannerBg+')'">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">Vector硬件产品</h1>
				<p data-caption-delay="100" class="wow fadeInRight">
					为客户提供开发、分析和优化车辆网络和ECU所需的各种硬件，包括连接电脑和总线/网络的接口（如CAN/CAN
					FD/LIN/J1708/以太网/FlexRay等）、数据记录仪、可扩展的标定和测量硬件以及HIL系统的模块化测试硬件</p>
				<div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<div class="all">
			<div class="Vector-hardware-products-background  wow fadeInRight">
				<div class="Vector-hardware-product container">
					<el-row>
						<el-col :span="24">
							<div class="headquarters">Vector硬件产品</div>
						</el-col>
					</el-row>
				</div>
				<div v-for="item in detailedInformation" :key="item.id" class="card-div container wow fadeInLeft">
					<div class="inside-div">
						<el-row>
							<el-col :span="24">
								<p class="product-title">{{item.title}}</p>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<p v-if="item.summary !== ''" class="product-summary">{{item.summary}}</p>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<p v-if="item.detailed !== ''" class="product-detailed">{{item.detailed}}</p>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<p v-if="item.summary1 !== ''" class="product-summary">{{item.summary1}}</p>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<p v-if="item.detailed1 !== ''" class="product-detailed">{{item.detailed1}}</p>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			<bzInfo style="margin-top: 0" />
		</div>
		<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from "@/components/MainNav.vue";
	import mainFooter from "@/components/MainFooter.vue";
	import "@/style/vector/hardware.less";
	import bzInfo from "@/components/BzInfo.vue";
	import bannerBg from '@/assets/images/banner-product.jpg';

	export default {
		name: "vectorHardware",
		components: {
			mainFooter,
			mainNav,
			bzInfo
		},
		data() {
			return {
				bannerBg: bannerBg,
				activeIndex: '3-1',
				detailedInformation: [{
						id: "1",
						title: "Smart Logger",
						summary: "Smart Logger用于电动汽车和ADAS开发的智能记录解决方案。",
						detailed: "借助新的Smart Logger产品组，Vector打破了用户控制的测量软件和自主运行的记录器之间的经典界限：在车辆中安装记录器硬件。用它来连接测量系统、ECU、总线系统、ADAS 传感器、摄像头、GNSS接收器等等。然后轻松地将现有的CANape 或vMeasure exp配置传输到Smart Logger。这提供了独特的过程可靠性。操作员只需要移动UI(基于Web的界面)来控制记录仪。",
						summary1: "",
						detailed1: "",
					},
					{
						id: "2",
						title: "VH1160",
						summary: "VH1160是一款基于USB的紧凑型测试硬件，可自动执行CAN和LIN的ECU一致性测试。它通常被基于CANoe的测试实现用于在每个测试用例之前自动重置硬件端的ECU。电流测量用于确定ECU状态(睡眠/唤醒)。在网络线路上创建短路的能力允许客户对ECU的稳健性执行简单的测试。",
						detailed: '',
						summary1: "",
						detailed1: "",
					}, {
						id: "3",
						title: "VH5110",
						summary: "VH5110借助VH5110A(也称为“CCS 监听器”),可以基于CCS协议分析充电站(EVSB)和电动汽车(EV)之间的通信。VH5110A“侦听”通过电力线通信(PLC)在控制导频线(CP)上通信的数据，并将它们转换为在CANoe中解释的以太网数据包。此外，基本通信的PWM参数被测量并作为系统变量显示在CANoe中。",
						detailed: '',
						summary1: "",
						detailed1: "",
					},
					{
						id: "4",
						title: "VH6501",
						summary: "VH6501是款灵活而紧凑的CAN/CANFD干扰硬件和CANoe网络接口组合在一个设备中。特别是，干扰硬件和网络接口的组合使得CAN/CANFD一致性测试的测试设置非常简单，无需额外的网络接口或特殊电缆。",
						detailed: '',
						summary1: "",
						detailed1: "",
					}, {
						id: "5",
						title: "VN0601",
						summary: "VN0601是用于ARINC 429总线系统的紧凑而强大的接口。该接口通过USB 2.0方便地利用PC连接，不需要外部电源，并且具有用于获取其他测量参数的模拟/数字接口。VN0601为飞机联网电子单元(线路可更换单元或LRU)的开发人员提供了用于测试和总线分析的高性能接口解决方案。",
						detailed: '',
						summary1: "",
						detailed1: "",
					},
					{
						id: "6",
						title: "VN1530/VN1531",
						summary: "VN1530/VN1531-通过PCle的CAN/LIN总线连接的硬件。",
						detailed: 'CAN/LIN网络接口VN1530和VN1531非常适合通过PCle接口访问CAN和LIN网络。由于其与CAN/CANFD、LIN、K-Line和SENT的总线连接的各种通道组合，VN1530 和VN1531是测试台、测试PC和机架使用的最佳接口。',
						summary1: "",
						detailed1: "",
					}, {
						id: "7",
						title: "VN1600",
						summary: "VN1600接口系列提供对CAN/CANFD和LIN网络的灵活快速访问。",
						detailed: "带有2个通道的VN1610/VN1611专注于最小尺寸和重量，而带有4个通道的VN1630AVN1640A则专为灵活性和IO 支持而设计。",
						product: "最新的VN1670接口现在可提供多达15个通道的解决方案，以实现更高的灵活性和最佳的网络访问。\n" + "VN1630日志提供额外的记录功能。"
					},
					{
						id: "8",
						title: "VN2640",
						summary: "VN2640具有USB 2.0的强大MOST150接口，MOST网络的开发、模拟、测试和维护需要强大、稳健和用户友好的硬件接口。快速MOST接口VN2640(用于MOST150)是笔记本电脑或台式机使用的理想解决方案。",
						detailed: '',
						summary1: "",
						detailed1: "",
					}, {
						id: "9",
						title: "VN4610",
						summary: "VN4610接口是针对基于IEEE 802.11p和CAN/CANFD的应用的特殊解决方案。它旨在最佳匹配CANoe选项.Car2x。这使得VN4610成为CANoe.Car2x测试工具的理想扩展，可让基于802.11p的ECU走上量产之路。",
						detailed: "VN4610支持接收和发送用于实现Car2x/N2X应用的IEEE 802.11p帧。内置GNSS接收器提供当前时间和当前位置。所有提供的时间戳都可以与UTC时间同步。",
						summary1: "",
						detailed1: "",
					},
					{
						id: "10",
						title: "vN5611/VN5612/VN5601",
						summary: "vN5611VN5612/VN5601:VN5611和VN5612是非常紧凑和方便的接口产品，适用于汽车以太网IEEE 100BASE-T1/1000BASE-T1(VN5611)和标准以太网IEEE100BASE-TX/1000BASE-T(VN5612)。它们用于以太网网络分析以及以太网环境中的仿真和测试任务。由于外形小巧紧凑，这些设备非常适合便携式使用。USB 3.0用作与计算机的接口。",
						detailed: "VN5601是一款非常紧凑的高性能USB转以太网适配器，适用于标准IEEE1000BASE-T和IEEE 2.5GBASE-T。它用于将具有标准以太网接口的设备轻松连接到PC可以在不影响Windows网络基础设施的情况下使用。该设备提供准确的硬件时间戳，并允许通过IEEE 1588(PTP)同步连接的设备。",
						summary1: "",
						detailed1: "",
					}, {
						id: "11",
						title: "VN5620/VN5430:VN5620",
						summary: "VN5620是一个紧凑而强大的接口，用于以太网网络的分析、仿真、测试和验证。VN5620接口支持各种可能的应用。",
						detailed: "它适用于与其他总线系统、网络参与(例如在模拟和生成帧、测试中的负载和错误)的同步以太网监控。用户可以使用以太网(1000BASE-T)或USB 3.0作为与计算机的接口。",
						summary1: "VN5430是一个紧凑的接口，用于以太网环境中的仿真和测试任务。",
						detailed1: "用户可以从VN5430的多功能配置选项中受益。可以实现广泛的模拟和测试场景，同时可以维护网络拓扑。该设备也可以用作独立开关。以太网(1000BASE-T)用作计算机的接口。\n" +
							"这两种设备都适合在办公室和测试台上使用。相应地设计温度和电压范围。",
					},
					{
						id: "12",
						title: "VN5650/VN5240",
						summary: "VN5650是一个强大的以太网接口，在12个端口上支持100/1000BASE-T1。",
						detailed: "适用于以太网与其他总线系统的同步监控。此外，VN5650为残余总线仿真提供最佳网络访问，并可用于测试期间的帧、负载和故障生成。由于其坚固的外壳和各种安装和堆叠能力，该设备非常适合在车辆中使用。以太网(10GBASE-T)或USB 3.0用作计算机的接口。",
						summary1: "VN5240是专为监控和分析以太网而设计的接口。",
						detailed1: "对于记录车辆中的以太网数据，VN5240非常适合与VP6400智能记录平台结合使用。该接口通过以太网(10GBASE-T)连接到计算机。分接的数据流也可以通过最多三个镜像端口非常灵活地访问。",
					}, {
						id: "13",
						title: "VN7572",
						summary: "VN7572:FlexRay网络接口VN7572非常适合通过PCle接口开发、模拟或测试FlexRay网络。",
						detailed: "用于与FlexRay、CAN、CANFD和LIN(K-Line)的总线连接的多功能通道组合使其成为实验室或开发人员工作站的理想接口。",
						summary1: "",
						detailed1: "",
					},
					{
						id: "14",
						title: "VN7610",
						summary: "FexRay网络接口VN7610非常适合FlexRay网络的开发、模拟或测试。通过USB连接的可能性使其普遍适用(例如在实验室或试驾期间)。由于其非常紧凑的设计，VN7610特别适用于移动应用。",
						detailed: "除了FlexRay总线连接外，该接口还提供对CAN、CANFD总线系统的访问。",
						summary1: "",
						detailed1: "",
					}, {
						id: "15",
						title: "VN7640",
						summary: "VN7640接口是适用于FlexRay、CAN、CANFD、LIN、K-Line和J1708应用的灵活解决方案，此外，以太网还支持不同的用例。",
						detailed: "由于各种通道组合，VN7640是实验室或开发人员工作站的理想接口。由于其紧凑而坚固的设计它也适用于移动应用。",
						summary1: "",
						detailed1: "",
					},
					{
						id: "16",
						title: "VN8810",
						summary: "VN8810是一款紧凑型智能诊断硬件设备，适用于涉及车辆诊断的各种应用。通过OBD插座可以轻松访问车辆。",
						detailed: "ECU的重新编程或诊断脚本的执行由一个硬件设备处理。一旦闪存包或脚本被传输，VN8810就会在独立模式下自主执行它们。\n" +
							"此外，VN8810还可作为远程诊断的接入点。因此，用户无需在现场就可以直接和交互地诊断世界各地的车辆。",
						summary1: "",
						detailed1: "",
					}, {
						id: "17",
						title: "VN8900",
						summary: "VN8900网络接口是模块化设计的接口硬件，具有CAN、CANFD、LIN、FlexRay、J1708和K-Line的各种可能的通道组合。",
						detailed: "这里的一个特别关注点是并行访问多个总线通道和I/O，对实时性和延迟有很高的要求，即使在独立操作模式下也是如此。",
						summary1: "",
						detailed1: "",
					},
					{
						id: "18",
						title: "vT System",
						summary: "vT System要彻底测试ECU，不仅需要将通信网络连接到测试系统，还需要连接/O接口。此任务由VectorVT系统处理。它极大地简化了测试台架和HIL测试系统的设置，因为它在一个模块中集成了连接I/O通道所需的所有电路组件。",
						detailed: "I/O通道的示例是用于控制前照灯的ECU输出或用于连接亮度传感器的输入。",
						summary1: "",
						detailed1: "",
					}, {
						id: "19",
						title: "VX0312",
						summary: "VX0312是一款以太网/BroadR-Reach USB适配器，具有集成CAN-FD连接，专为满足汽车要求而开发。以太网上的XCP或BroadR-Reach设备上的XCP可以通过VX0312快速可靠地连接到安装了测量和校准软件的计算机。",
						detailed: "",
						summary1: "",
						detailed1: "",
					},
					{
						id: "20",
						title: "VX1000",
						summary: "VX1000基本模块通过以太网上的XCP连接到PC, 这是一种独立于OEM的ASAM标准，广泛用于汽车行业。VX1000测量硬件通过POD(即插即用设备)连接到ECU。根据可用的微控制器接口，可以使用数据跟踪或复制方法来获取测量数据。",
						detailed: "",
						summary1: "",
						detailed1: "",
					},
					
					{
						id: "21",
						title: "VH4110",
						summary: "是用于测试智能设备的 USB 路由器。VH4110 硬件 （ 也称为 “IoT Enabler” ） 无需互联网连接即可将受测无线智能设备连接到 CANoe 开发和测试工具。在典型的测试设置中，VH4110硬件可以用作路由器，用于CANoe主机和被测设备之间基于IP的通信，例如，通过（无线）LAN连接到本地网络。此外，具有非基于IP的无线电协议的设备也可以本地连接，例如低功耗蓝牙（BLE）或ZigBee。",
						detailed: "",
						summary1: "",
						detailed1: "",
					},
					{
						id: "22",
						title: "CSM Measurement Modules——CSM测量模块",
						summary: "CSM测量模块是精准、坚固、模块化的移动数据采集设备,按应用场景可分为CAN MiniModules、ECAT MiniModules以及High Voltage Measurement Modules。测量技术广泛应用于汽车、工程机械等行业的产品开发流程中。Vector公司和CSM公司合作为用户提供完整的测量解决方案,覆盖数据采集、数据显示及记录、数据挖据、报告生成以及大数据管理等方面。该方案基于CAN、EtherCAT以及XCP on Ethernet协议,可以满足快速、灵活、高精度的测量和记录需求, 为用户分析和解决复杂问题提供强有力的支撑。",
						detailed: "",
						summary1: "",
						detailed1: "",
					},
					{
						id: "23",
						title: "GL Loggers——GL记录仪",
						summary: "GL记录仪是专用的汽车数据记录仪，可用于测试车辆或测试台上。它们记录测量值并获取CAN/CAN FD, LIN, FlexRay和以太网总线系统的数据通信。除了总线通信，GL还支持通过CCP/XCP和诊断存储额外的内部ECU参数，以及以时间同步方式的数字和模拟输入的测量值。",
						detailed: "",
						summary1: "",
						detailed1: "",
					},
					{
						id: "24",
						title: "VIO System",
						summary: "测试中的设备通常有大量的I/O连接到执行器、传感器或完整ECU，一个灵活的测试系统必须在功能测试期间稳定激励输入并分析系统反应或输出。借助VIO System，Vector提供了一个紧凑的、模块化和可扩展的I/O测试系统执行I/O测试。",
						detailed: "",
						summary1: "",
						detailed1: "",
					},
				],
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
			}
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			}
		},
		mounted() {
			document.title = "Vector硬件产品 - 产品中心 - 东信创智"
			new this.$wow.WOW().init()
		}
	}
</script>

<style scoped>

</style>